<template>
  <div class="main-container">
    <Header />

    <TopCarousel :sliders="slider.WilliUBusiness" />

    <AboutSectionFour
      :data="williubusiness.about"
      :title="'¿Qué es Willi-U?'"
    />

    <!--Our clients section-->
    <ProjectGridSlider
      :data="williubusiness.owrClients"
      :title="'Nuestros Clientes'"
      :height="'height90'"
    />

    <!--<div>
      <img
        src="/assets/img/banners/williubusiness/2.jpg"
        style="background-size: cover;width: -webkit-fill-available;"
      />
    </div>-->

    <!--<div>
      <img
        src="/assets/img/banners/williubusiness/1.png"
        style="background-size: cover;width: -webkit-fill-available;"
      />
    </div>-->

    <!--Owr services section-->
    <LatestProject
      :data="williubusiness.implementations"
      :title="'Nuestros Servicios'"
    />

    <!--Advantage-->
    <div class="page-wrapper section-space--inner--60">
      <div class="service-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h2 class="text-center" style="padding-bottom: 20px;">
                Ventajas competitivas
              </h2>
              <div class="service-details">
                <div class="content section-space--top--30">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <img
                          style="max-width: 95%;"
                          src="/assets/img/banners/williubusiness/7.png"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="team-job__content-wrapper">
                        <div class="team-job__list-wrapper">
                          <div class="team-job__single">
                            <h3 class="title">
                              Portafolio amplio de Categorías
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Ofrece un amplio portafolio de posibilidades de
                              venta para comida rápida, restaurantes, bodegones,
                              automercados, panadería, licorerías y un sin fín
                              de proveedores relacionados a la venta de
                              alimentos y bebidas.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Múltiples Sucursales
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Willi-U tiene la capacidad de configurar un
                              negocio con todas sus sucursales a nivel nacional,
                              permitiendo de esta forma que una marca tenga
                              presencia con todas sus tiendas.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Múltiples Configuraciones
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Permite configurar la forma en cómo se realizan
                              los deliveries incluyendo la posibilidad de pick
                              up para aquellos usuarios que están en la calle,
                              así mismo lleva un control del paso a paso de los
                              pedidos de cada vendedor que es administrado por
                              el módulo back de Willi-U.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Boton de pago integrado
                            </h3>
                            <p class="text" style="text-align: justify;">
                              PayFor-U, es una herramienta de pagos multimoneda
                              que utiliza diferentes Gateway Payments.
                              Próximamente administrará el pago a través de
                              cupones.
                            </p>
                          </div>
                          <div class="team-job__single">
                            <h3 class="title">
                              Soporte y Servicio
                            </h3>
                            <p class="text" style="text-align: justify;">
                              Cuenta con su propio gestor de comunicaciones
                              entre los vendedores, clientes y transportistas,
                              además de contar con un centro de soporte de
                              operaciones de LikeU Group.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CarouselPlanSection2
      :title="'Planes de Contratación'"
      :subtitle="'Los costos varían según el uso que deseas darle'"
      :platform="4"
      :plse_type="'store'"
    />

    <div class="about-section section-space--inner--60" id="contact-form">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold;">
              Preguntas frecuentes
            </h2>
          </div>
          <div class="col-lg-6 col-12 mt--30" style="padding-top: 20px;">
            <div v-for="item in williubusiness.FAQ" :key="item.id">
              <Accordion :question="item.question" :answer="item.answer" />
            </div>
          </div>
          <div class="col-lg-6 col-12 mb-30">
            <ContactForm :platform="4" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-section section-space--inner--60">
      <div class="container">
        <div class="row row-25">
          <div class="col-12">
            <h2 class="subtitle text-center" style="font-weight: bold;">
              Conoce nuestro Ecosistema Digital
            </h2>
          </div>
        </div>
      </div>
    </div>

    <Carousel2 :data="slider.ecosystem" />

    <BrandCarousel addClass="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- WhatsApp Bottom -->
    <div id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=+584142698744&text=¡Hola!%20Quiero%20más%20información%20acerca%20de%20sus%20servicios."
        title="Escríbenos por Whastapp"
        data-tap="tooltip"
      >
        <i
          class="fa fa-whatsapp"
          style="color: #ffffff;"
          aria-hidden="true"
        ></i>
      </a>
    </div>

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="100px" right="10px">
      <i class="ion-android-arrow-up"></i>
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import slider from "../data/slider.json";
import data from "../data/service.json";
import williubusiness from "../data/williubusiness.json";
import Header from "@/components/Header";
import AboutSectionFour from "../components/sections/AboutSectionFour";
import HeroTwo from "../components/sections/HeroTwo";
import Carousel1 from "../components/sections/Carousel1";
import TopCarousel from "../components/TopCarousel";
import Carousel2 from "../components/sections/Carousel2";
import ProjectGridSlider from "@/components/sections/ProjectGridSlider";
import Breadcrumb from "../components/Breadcrumb";
import TeamMember from "../components/sections/TeamMember";
import LatestProject from "../components/sections/LatestProject";
import Accordion from "../components/Accordion";
import ContactForm from "../components/ContactForm";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import ServiceDetailsSidebar from "@/components/ServiceDetailsSidebar";
import ServiceDetailsMain from "@/components/ServiceDetailsMain";
import CarouselPlanSection2 from "@/components/sections/CarouselPlanSection2";

export default {
  components: {
    Header,
    HeroTwo,
    LatestProject,
    Breadcrumb,
    Accordion,
    ContactForm,
    TopCarousel,
    ServiceDetailsMain,
    ProjectGridSlider,
    TeamMember,
    AboutSectionFour,
    ServiceDetailsSidebar,
    Carousel1,
    Carousel2,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CarouselPlanSection2
  },
  data() {
    return {
      config: {
        headers: {
          Authorization: ""
        }
      },
      data,
      slider,
      williubusiness,
      orderData: "",
      user_id: ""
    };
  },
  beforeCreate() {
    if (sessionStorage.getItem("country") === null) {
      sessionStorage.setItem("country", "057-venezuela");
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.breadcrumb-area {
  min-height: 0px;
}
.swiper-wrapper {
  padding: 0px !important;
}
.login-form {
  min-height: 200px;
  align-items: center;
  justify-content: center;
}
.ht-btn--round {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: -10%;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin: auto;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom !important;
  background-color: #e94e1b;
}
.breadcrumb-area::before {
  min-height: 0px;
  background-color: #00000000;
}
#whatsapp {
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 20px;
  display: block;
  color: #ffffff;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  background-color: #00e676;
}
@media only screen and (max-width: 479px) {
  .scroll-top {
    right: 10px !important;
    bottom: 80px !important;
  }
  #whatsapp {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202020;
  font-weight: 600;
  line-height: 35px !important;
}
</style>
